<template>
  <v-container>
    <h1>Dashboard</h1>
  </v-container>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'Dashboard',
  computed: {
    ...mapState({
      token: state => state.auth.token,
    }),
  },
}
</script>
